import { createTheme } from '@mui/material/styles';

const basetheme = createTheme({})

const BLACK = '#222'
const BLACKBG = '#222'
const WHITE = '#fff'
const WHITEBG = '#fff'
const GREY = '#22'
const BC = "#FFD952"
const BCBRIGHT = "#FF7C52"
const BCGRADIENT = "linear-gradient(0deg, #FF7C52 0%, #FFD952 20%)"
const FC = "#2EDFCD"
const FCBRIGHT = "#2E95DF"
const FCGRADIENT = "linear-gradient(0deg, #2E95DF 0%, #2EDFCD 20%)"
const CC = "#A278E8"
const CCBRIGHT = "#E878A0"
const CCGRADIENT = "linear-gradient(0deg, #E878A0 0%, #A278E8 20%)"

const DARK_WHITE = '#DADADA'
const ACCENT = '#99f'
const ACCENTBG = '#6e40c9'
const ACCENT_DARK = '#009adc'
const TYPOGRAPHY = {
    h1: {
        fontSize: '5rem',
        fontWeight: 600,
        fontFamily: 'Kanit, sans-serif',

    },
 
    h2: {
        fontSize: '3rem',
        fontWeight: 600,
        fontFamily: 'Kanit, sans-serif',

    
    },
    h2alt: {
        fontSize: '1.6rem',
        fontWeight: 400,
    },
    h3: {
        fontWeight: 900,
        letterSpacing: "1px",
        fontFamily: 'Kanit, sans-serif',
        lineHeight: 0.9,
        color: GREY
    },
    h4: {
        fontSize: '1.2rem',
        fontWeight: 800,
        letterSpacing: "2px"

    },
    sp_headline: {
        fontWeight: 900,
        fontSize: '1.5rem',
        letterSpacing: "0.8px",
        lineHeight: 0.9,
        marginBottom: "1rem"
    },
    sp_description: {
        fontSize: '1.0rem',
        fontWeight: 400,
        letterSpacing: "2px"
    },
    kf_headline: {
        fontWeight: 900,
        lineHeight: 0.9,
        letterSpacing: "0.8px",
    },
    kf_description: {
        fontSize: '0.5rem',
        fontWeight: 400,
        letterSpacing: "1.4px",
    },
    subtitle1: {
        fontSize: '6.5rem',
        fontWeight: 900,
        lineHeight: 0.7
    },
    subtitle2: {
        fontSize: '6.2rem',
        fontWeight: 900,
        lineHeight: 0.7


    },
    slicecontent1: {
        fontSize: '1.5rem',
        fontWeight: 300,
    },
    slicecontent2: {
        fontSize: '1.2rem',
        fontWeight: 300,

    },
    label: {
        fontSize: '1.6rem',
        fontWeight: 800,
    },
    label_small: {
        fontSize: '1.3rem',
        fontWeight: 800,
    },
    list: {
        fontSize: '1.3rem',
        fontWeight: 500,
        listStyleType: "circle"
    },
    price1: {
        fontSize: '2.5rem',
        fontWeight: 600,
    },
    price2: {
        fontSize: '2rem',
        fontWeight: 600,
    },
    price3: {
        fontSize: '1.5rem',
        fontWeight: 600,
    },
    price4: {
        fontSize: '1rem',
        fontWeight: 600,
    },
    desc: {
        fontSize: '1rem',
        fontWeight: 400,
        fontStyle: "italic",
        marginLeft: ".5rem",
        color: "#666",
        letterSpacing: "2px"
    },
    p: {
        fontSize: '1rem',
        fontWeight: 400,
        fontSize: '1em',
        color: "#666666",
    },
    tiny: {
        fontSize: '0.9rem',
        fontWeight: 400,
        color: "#666666"
    },
    fontFamily: 'Roboto, sans-serif',
    fontSize: 16,

}


const darktheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: WHITE,
            contrastText: WHITE,
        },
        secondary: {
            main: WHITE,
            contrastText: BLACK
        },
        background: {
            default: BLACKBG,
            paper: "#7d8790",
        },
        text: {
            primary: WHITE,
            secondary: '#7d8790',
            disabled: '#7d8790',
            icon: '#ffffff',
            dark: '#11151a',
            highlight: WHITE
        },
        action: {
            active: '#ff0000',
            hover: '#58a3e2',
            selected: '#ff00ff',
            focus: '#ff00ff',
            disabled: '#ff0000',
        }
    },
    typography: TYPOGRAPHY
});

const lighttheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: BLACK,
            contrastText: WHITE,
        },
        secondary: {
            main: GREY,
            contrastText: WHITE
        },
        background: {
            default: WHITEBG,
            secondary: DARK_WHITE,
            paper: GREY,
        },
        text: {
            primary: BLACK,
            secondary: '#7d8790',
            disabled: '#7d8790',
            icon: '#ffffff',
            dark: '#11151a',
            highlight: "#fae246",
            white: WHITE
        },
        action: {
            active: '#7d8790',
            hover: '#58a3e2',
            selected: '#ff00ff',
            focus: '#ff00ff',
            disabled: '#464c54',
        }
    },
    typography: TYPOGRAPHY
});

const accenttheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: WHITE,
            contrastText: WHITE,
        },
        secondary: {
            main: WHITE,
            contrastText: WHITE
        },
        background: {
            default: ACCENTBG,
            paper: BLACK
        },
        text: {
            primary: WHITE,
            secondary: '#7d8790',
            disabled: '#7d8790',
            icon: '#ffffff',
            dark: '#11151a',
            highlight: "#fae246",
            paper: WHITE
        },
        action: {
            active: '#7d8790',
            hover: '#58a3e2',
            selected: '#ff00ff',
            focus: '#ff00ff',
            disabled: '#464c54',
        },
        paper: {
            background: "red"
          }

    },
    typography: TYPOGRAPHY
});

const bctheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: BLACK,
            contrastText: WHITE,
        },
        secondary: {
            main: WHITE,
            contrastText: WHITE
        },
        background: {
            default: BC,
            bright: BCBRIGHT,
            gradient: BCGRADIENT,
            paper: BLACK,
            white: WHITE
        },
        text: {
            primary: BLACK,
            secondary: '#7d8790',
            disabled: '#7d8790',
            icon: '#ffffff',
            dark: '#11151a',
            highlight: "#fae246",
            paper: WHITE
        },
        action: {
            active: '#7d8790',
            hover: '#58a3e2',
            selected: '#ff00ff',
            focus: '#ff00ff',
            disabled: '#464c54',
        },
        paper: {
            background: "red"
          }

    },
    typography: TYPOGRAPHY
});

const fctheme  = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: BLACK,
            contrastText: WHITE,
        },
        secondary: {
            main: WHITE,
            contrastText: WHITE
        },
        background: {
            default: FC,
            gradient: FCGRADIENT,
            bright: FCBRIGHT,
            paper: BLACK,
            white: WHITE
        },
        text: {
            primary: BLACK,
            secondary: '#7d8790',
            disabled: '#7d8790',
            icon: '#ffffff',
            dark: '#11151a',
            highlight: "#fae246",
            paper: WHITE
        },
        action: {
            active: '#7d8790',
            hover: '#58a3e2',
            selected: '#ff00ff',
            focus: '#ff00ff',
            disabled: '#464c54',
        },
        paper: {
            background: "red"
          }

    },
    typography: TYPOGRAPHY
}); 

const cctheme  = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: BLACK,
            contrastText: WHITE,
        },
        secondary: {
            main: WHITE,
            contrastText: WHITE
        },
        background: {
            default: CC,
            gradient: CCGRADIENT,
            bright: CCBRIGHT,
            paper: BLACK,
            white: WHITE
        },
        text: {
            primary: BLACK,
            secondary: '#7d8790',
            disabled: '#7d8790',
            icon: '#ffffff',
            dark: '#11151a',
            highlight: "#fae246",
            paper: WHITE
        },
        action: {
            active: '#7d8790',
            hover: '#58a3e2',
            selected: '#ff00ff',
            focus: '#ff00ff',
            disabled: '#464c54',
        },
        paper: {
            background: "red"
          }

    },
    typography: TYPOGRAPHY
}); 

export { darktheme, lighttheme, accenttheme, bctheme, fctheme, cctheme };