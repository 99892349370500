import React, { useState, useEffect, useRef } from 'react';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CircleIcon from '@mui/icons-material/Circle';
import { Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LensIcon from '@mui/icons-material/Lens';

const ImageCarousel = ({ imageArray }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [images, setImages] = useState(imageArray);
    const [isInteracting, setIsInteracting] = useState(true);
    const [isVisiblePrev, setIsVisiblePrev] = useState(false);
    const [isVisibleNext, setIsVisibleNext] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const timeout = useRef(null)

    const element = useRef(null);
    // const interval = useRef(null);

    const isElementInViewport = (el) => {

        var rect = el.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
        );
    }

    // Create an intersection observer to observe the carousel element
    const observer = new IntersectionObserver((entries) => {
        // Check if the carousel element is intersecting with the viewport
        if (entries[0].isIntersecting) {
            // If it is, start the interval
            setCurrentIndex(0)
            setInitialized(true)
            setIsInteracting(false)
            console.log("intersecting")
        }
        else {
            // If it is not, clear the interval
            setIsInteracting(true)
            setInitialized(false)
            console.log("not intersecting")
        }
    });

    const handleMouseOverPrev = () => {
        setIsVisiblePrev(true);
    };

    const handleMouseOutPrev = () => {
        setIsVisiblePrev(false);
    };

    const handleClickPrev = (direction) => {
        setIsInteracting(true)
        timeout.current && clearTimeout(timeout.current)
        setIsVisiblePrev(true);
        previousSlide();
        timeout.current = setTimeout(() => {
            setIsVisiblePrev(false);
        }, 1000);
    };

    const handleMouseOverNext = () => {
        setIsVisibleNext(true);
    };

    const handleMouseOutNext = () => {
        setIsVisibleNext(false);
    };

    const handleClickNext = (direction) => {
        setIsInteracting(true)
        timeout.current && clearTimeout(timeout.current)
        setIsVisibleNext(true);
        nextSlide();
        timeout.current = setTimeout(() => {
            setIsVisibleNext(false);
        }, 1000);
    };

    useEffect(() => {

        if (!isInteracting || !initialized) return

        console.log("isInteracting", isInteracting)
        setTimeout(() => {
            setIsInteracting(false);
        }, 5000);

    }, [isInteracting])

    useEffect(() => {
        // Observe the carousel element
        observer.observe(element.current);

        return () => {
            // Disconnect the observer when the component is unmounted
            observer.disconnect();
        }
    }, []);

    //create a function that detects if the element is in the viewport
    //if it is, then start the interval
    //if it is not, then clear the interval


    useEffect(() => {
        const interval = setInterval(() => {
            // Check if we are at the last slide, if so, go back to the first slide
            if (isInteracting) return
            if (currentIndex === images.length - 1) {
                setCurrentIndex(0);
            } else {
                setCurrentIndex(currentIndex + 1);
            }
        }, 3000);
        return () => clearInterval(interval);
    }, [currentIndex, isInteracting]);

    const previousSlide = () => {
        setCurrentIndex(currentIndex === 0 ? images.length - 1 : currentIndex - 1);
    };

    const nextSlide = () => {
        setCurrentIndex(currentIndex === images.length - 1 ? 0 : currentIndex + 1);
    };

    const Indicator = ({ index }) => (
        <span
            style={{
                margin: '0 0px',
                cursor: 'pointer',       
                padding: '8px',        
               
                color: index === currentIndex ? '#fff' : '#555',
            }}
            onClick={() => setCurrentIndex(index)}
        >

            <LensIcon style={{  width: '10px',
                height: '18px',}} />
          

        </span>
    );

    return (
        <div style={{  maxWidth: "40vh" }} ref={element}>

            <div style={{ overflow: "hidden", boxShadow: "0px 0px 200px #1a1a1f", position: "relative" }}>
                
                <div
                    style={{
                        display: 'flex',
                        transition: '.5s ease-in-out',
                        transform: `translateX(-${currentIndex * 100}%)`
                    }}
                    onMouseEnter={() => setIsInteracting(false)}
                    onMouseLeave={() => setIsInteracting(false)}

                >

                    {images.map((image, index) => (
                        <img
                            src={image}
                            style={{
                                minWidth: '100%',
                                minHeight: '100%',

                                transition: '.5s ease-in-out',
                                margin: index === 0 ? '0' : '0 0 0 0px',
                                // Add a motion blur effect to the current slide
                                filter: index === currentIndex ? "none" : "saturate(0)",
                                // Add a transition to the motion blur effect
                                transition: "filter 2.5s ease",
                            }}
                        />
                    ))}
                </div>

            </div>

            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", position: "relative", justifyContent:"center" }}>
                {images.map((_, index) => (
                    <Indicator key={index} index={index} />
                ))}
            </div>
            
        </div>
    );
};


export default ImageCarousel;


// <button onClick={previousSlide}>&larr;</button>
//<button onClick={nextSlide}>&rarr;</button>