import Typography from '@mui/material/Typography';
import * as React from 'react';

import Button from '@mui/material/Button';

import { Box, Grid, Stack, ThemeProvider } from '@mui/material';
import { PrismicRichText } from '@prismicio/react';
import { accenttheme, darktheme, lighttheme } from '../themes';

import { useRef } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


function htmlSerializer(type, element, text, children) {
    if (type === 'label')
        return `<strong className="${element.data.label}">${children}</strong>`
    return null
}

const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const MODE_TEXT = "text";
const MODE_MEDIA = "media";
const MODE_TEXT_MEDIA = "text-media";


const CustomerLogos = ({ sliceData, index, direction }) => {
    let mode = MODE_TEXT_MEDIA

    let theme = lighttheme;
    const imageRef = useRef(null);
    const imageSliceRef = useRef(null);
    const containerRef = useRef(null);
    const rootRef = useRef(null);

    React.useLayoutEffect(() => {

        const numItems = sliceData.items.length;


        if (!containerRef.current) return;

        let links = gsap.utils.toArray(".customerLogo");

        links.forEach((a, index) => {

            gsap.fromTo(
                a,
                {
                    opacity: 0,
                    y: 10,
                },
                {
                    y: 0,
                    delay: .2 * index * .4,
                    duration: 1,
                    opacity: 1,
                    ease: "easeIn",

                    scrollTrigger: {
                        id: "customerLogo",
                        trigger: a,
                        start: "top 80%",

                        markers: false,
                    },

                }
            )
        })


    }, [imageRef.current, imageSliceRef.current]);


    //console.log("button ------------------------------------>");
    //console.log(sliceData.primary.button_link)

    const buttonLink = null

    //sliceData.primary.button_link.target === "_blank" ? { url: sliceData.primary.button_link.url, type: "external" } : sliceData.primary.button_link.url ? { url: "#" + sliceData.primary.button_link.url.split("#")[1], type: "internal" } : null;

    //console.log("===============================)> buttonLink ")
    //console.log(buttonLink)

    //console.log("sliceData");
    // console.log(sliceData);
    /*

    switch (sliceData.primary.color_theme) {
        case "Bright":
            theme = lighttheme;
            break
        case "Dark":
            theme = darktheme;
            break
        case "Colorful Yellow":
            theme = accenttheme;
            break
        default:
            theme = lighttheme;
    }
*/
    /*
    if (sliceData.items[0].slider_image.url === undefined && (sliceData.primary.slice_title[0].text !== "" || sliceData.primary.sub_title[0].text !== "")) {
        //console.log("no media")
        mode = MODE_TEXT;
        //console.log("mode is inside " + mode);
    }
    else if (sliceData.primary.slice_title[0].text === "" && sliceData.primary.sub_title[0].text === "") {
        // console.log("no text")
        mode = MODE_TEXT;
        // console.log("mode is inside " + mode);
        mode = MODE_MEDIA;
    }
    else {
        // console.log("mode is inside " + mode);
    }

*/

    const Media = ({ url, link }) => {

        //console.log("Media")
        //console.log(url)


        if (url === undefined) return null;
        //console.log("sliceData.items[0].slider_image.url " + url)
        return (
                <img src={url} alt="image" style={{ width: "100%", height: "auto", objectFit:"contain", maxHeight: "50px", cursor:"pointer" }} onClick={() => { if (link) window.open(link.url, link.type) }} />
        )

    }
    /*
        console.log("%c-------------, SellingPointsSlice", "color: red; font-size: 20px;)");
        console.log(sliceData.primary.slice_visible);
    */
    if (sliceData.primary.slice_visible) {
        


        console.log("%c-------------, CUSTOMER LOGOS", "color: pink; font-size: 70px;)");

        let itemWidth = 6;



        return (
            <ThemeProvider theme={theme}>
                <Box ref={containerRef} sx={{ display: "flex", alignItems: "center", position: "relative", backgroundColor: "background.default", px: { xs: 2, sm: 3, md: "15vw" }, py: { xs: "5vw", sm: "5vw", md: "5vw" } }}>

                    <Grid container spacing={{ xs: 2, md: 2 }} direction="row" alignItems="center" justifyContent="flex-start" sx={{ width: "100%", textAlign: "center", mb: "1em" }}>
                        {sliceData.items.map((item, index) => {
                            { console.log("item", item) }
                            return (
                                <Grid className="customerLogo" display="flex" alignItems="center" item xs={4} sm={3} md={3} key={index} sx={{ minHeight: "10vh" }}>
                                    <Media url={item.logo.url} link={item.link} />
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>

            </ThemeProvider>
        )
    } else {
        return null;
    }
}

export default CustomerLogos


/*
 <Stack
                    direction={"row"}
                    alignItems={"flex-start"}
                    ref={rootRef}   
                    sx={{  maskImage: "linear-gradient(to bottom, #000000 1%, #000000 10%, #000000 90%, #000000 99%);", overflow: "hidden", justifyContent: "flex-start", backgroundColor: "background.default", pt: { md: "30px", xs: "40px" }, pb: { md: "55px", xs: "60px" }, px: { md: "80px", xs: "30px" }}}
                    
                    >
                    
                    
                    */