import { Box, Button, Stack, ThemeProvider, Typography } from '@mui/material';
import * as React from 'react';

import { lighttheme } from '../themes';

import logo from '../assets/vxodsgn-logo-pos.svg';


import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { PrismicRichText } from '@prismicio/react';
import { useRef } from 'react';


const TopSlice = ({ sliceData, color, direction }) => {
  // Create a ref to store the current position of the "bgphones" image

  const topimgContainerRef = useRef(null);
  const txtHeaderRef = useRef(null);
  const txtSubHeaderRef = useRef(null);

  const containerRef = useRef(null);

  const buttonLink = { url: "#" + sliceData.link.url.split("#")[1], type: "internal" };

  return (
    <ThemeProvider theme={lighttheme}>

      <Stack className='slice' id="topSlice" direction="column"
        spacing={0} sx={{ overflow: "hidden", position: "relative", minHeight: "200vh", zIndex: 0, backgroundColor: "#fff", marginTop: "-80px", maxWidth: "100vw" }}
      >

        <Box id="topImage" sx={{ position: "static", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center", width: "100vw", height: "100dvh", zIndex: 1 }}>
          <img src={logo} alt="VXO Design" style={{ width: "80vw", height: "auto" }} />
        </Box>

        <Box id="scrollDownIndicator" sx={{ position: "fixed", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "flex-end", width: "100vw", height: "100vh", zIndex: 1, px: "10vw" }}>
          <ArrowCircleDownIcon sx={{ color: "#111", fontSize: "2rem", mb:20 }}  />
          </Box>
          

        <Box
          id="topContent" className="background-img" style={{ opacity: 0, }} sx={{position: "fixed", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center", width: "100%", height: "100vh", zIndex: 1, px: "10vw"}}
        >
          <Box sx={{ display: "flex", flex: 1 }} />
          <Box

            id="topText"
            sx={{ width: "100%", maxWidth: "1000px",  zIndex: 1, }}  
          >


            <PrismicRichText
              id="topHeadline"
              field={sliceData.title}
              components={{
                heading1: ({ children }) =>
                  <Typography
                    ref={txtHeaderRef}
                    id="topHeadlineEl"
                    variant="h1"
                    color="#fff"
                    sx={{
                      p: "0px",
                      mb: {xs: "0.3em", md: "0px"},
                      lineHeight: {xs: "0.9em", md: "1.1em"},

                      fontSize:
                      {
                        xs: "3.3rem",
                        sm: "5.5rem",
                        md: "6.4rem",
                        width: "100%",
                      },
                      textAlign: "left",
                      alignContent: "flex-start",
                    }}
                  >{children}</Typography>,

              }}
            />
            <Box sx={{ width: "100%",my: "0rem", maxWidth: "800px", ml: {xs: 0, md: 3} }} >
            <PrismicRichText
              field={sliceData.subtitle}
              components={{
                heading3: ({ children }) =>
                  <Typography
                    variant="h2alt"
                    id="topSubHeadlineEl"
                    sx={{
                      color: "#a6a5af",
                      
                      fontSize:
                      {
                        xs: "1.4rem",
                        sm: "1.9rem",
                        md: "1.8rem",
                      },
                      textAlign: "left",
                      alignContent: "flex-start",
                    }}>
                    {children}
                  </Typography>
              }}
              
            />
            </Box>
          </Box>

          <Box sx={{ px: "1rem", pt: "1rem", pb: "5rem", color: color, display: "flex", flex: 1, alignItems: "center"}}>
            <PrismicRichText
              field={sliceData.linktext}
              components={{
                heading6: ({ children }) =>
                  <Button variant="contained" href={buttonLink?.url} size="large" sx={{ fontWeight: "900", 
                  color:"#000", backgroundColor:"#fff", 
                  ':hover': {
                    bgcolor: '#bafffb', // theme.palette.primary.main
                    color: '#012324',
                  },
                  cursor: "pointer",  borderRadius: "30px", marginTop: "40px", py:2 , px: 6}}>
                    {children}
                  </Button>
              }}
            />

          </Box>
        </Box>

      </Stack>

    </ThemeProvider>
  );
};

export default TopSlice;