import AppBar from '@mui/material/AppBar';
import * as React from 'react';

import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import { Box, Divider, Drawer, IconButton, Stack, ThemeProvider } from '@mui/material';
import logob from '../assets/vxodsgn-logo-clean-pos.svg';
import logow from '../assets/vxodsgn-logo-clean-neg.svg';

import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';

import { accenttheme, darktheme } from '../themes';

const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];



const TopNavigation = () => {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    return (
        <ThemeProvider theme={darktheme}>
            <AppBar
                id="topNavigation"
                position="sticky"
                elevation={0}
                sx={{ backgroundColor: "#131313", height: "80px", backgroundBlendMode: "multiply", backdropFilter: "saturate(180%) blur(20px)" }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    alignContent='center'
                    spacing={2}
                    style={{ margin: "0px" }}
                >
                    <div style={{ height: "80px" }}>
                        <img src={logow} alt="VXO Design" style={{ height: "100%", objectFit: "contain", padding: "5px" }} />
                    </div>

                    { false &&
                    <Box sx={{ my: 1, px: 4.5, display: { md: 'block', sm: 'none', xs: 'none' } }}>
                        <nav>
                            
                            <Link
                                variant="button"
                                href="#"
                                sx={{ my: 1, mx: 1.5 , textDecoration: "none", pointer: "cursor" }}
                            >
                                Start
                            </Link>
                            <Link
                                variant="button"
                                href="#price"
                                sx={{ my: 1, mx: 1.5, textDecoration: "none", pointer: "cursor"  }}
                            >
                                Våra produkter
                            </Link>
                            <Link
                                variant="button"
                                href="#contact"
                                sx={{ my: 1, mx: 1.5 , textDecoration: "none", pointer: "cursor" }}
                            >
                                Consulting
                            </Link>
                            <Link
                                variant="button"
                                href="#contact"
                                sx={{ my: 1, mx: 1.5, textDecoration: "none", pointer: "cursor" }}
                            >
                                Om oss
                            </Link>
                        </nav>
                    </Box>
                    }

                    {false &&
                        <Box display="flex" justifyContent="right" p={2} sx={{ width: "200px", display: { md: 'flex', sm: 'flex', xs: 'flex' } }} >
                            <IconButton aria-label="menu" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                                <MenuIcon color="primary" sx={{ fontSize: 35 }} />
                            </IconButton>
                        </Box>
                    }
                </Stack>


            </AppBar>
            <Drawer open={isMenuOpen} PaperProps={{
                sx: {
                    backgroundColor: "#FFF",
                    color: "#000",
                    minWidth: "40%"
                }
            }} anchor={"right"} onClose={() => setIsMenuOpen(false)}>
                <Box display="flex" justifyContent="right" p={2}  >

                    <IconButton aria-label="menu" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        <CloseIcon  sx={{ fontSize: 35, color:"#000" }} />
                    </IconButton>
                </Box>
                <Box sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    flexDirection: "column",
                    gap: "20px",
                    flexWrap: "wrap",
                    margin: "auto",
                    my: 1, mx: 1.5
                }} >

                    <Link
                        variant="h2"
                        color="#000"
                        href="#"
                        sx={{ my: 1, mx: 1.5, textDecoration: 'none' }}
                        onClick={() => setIsMenuOpen(false)}
                    >
                        Start
                    </Link>
                    <Link
                        variant="h2"
                        color="#000"
                        href="#products"
                        sx={{ my: 1, mx: 1.5, textDecoration: 'none' }}
                        onClick={() => setIsMenuOpen(false)}
                    >
                        Våra produkter
                    </Link>
                    <Link
                        variant="h2"
                        color="#000"
                        href="#consulting"
                        sx={{ my: 1, mx: 1.5, textDecoration: 'none' }}
                        onClick={() => setIsMenuOpen(false)}
                    >
                        Consulting
                    </Link>
                    <Link
                        variant="h2"
                        color="#000"
                        href="#about"
                        sx={{ my: 1, mx: 1.5, textDecoration: 'none' }}
                        onClick={() => setIsMenuOpen(false)}
                    >
                        Om oss
                    </Link>

                </Box>

            </Drawer>
        </ThemeProvider>
    );
};
export default TopNavigation;
