import Typography from '@mui/material/Typography';
import * as React from 'react';

import Button from '@mui/material/Button';

import { Alert, Box, Grid, Link, Snackbar, Stack, TextField, ThemeProvider } from '@mui/material';
import { PrismicRichText } from '@prismicio/react';
import {darktheme, lighttheme } from '../themes';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const ContactSlice = ({ sliceData, index, direction }) => {
    const [validMail, setValidMail] = React.useState(false);
    const [formPosted, setFormPosted] = React.useState(false);
    const [formError, setFormError] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState("");
    let token = null

    const defaultValues = {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        business: "",
        message: "",
    };

    let theme = darktheme;
    const [formValues, setFormValues] = React.useState(defaultValues);

    const handleSubmit = async (event) => {

        const form = new FormData(event.currentTarget);

        //grecaptcha.ready(function() {
        // Add your logic to submit to your backend server here.
        //document.getElementById("token").value = token;
        //const formData = new FormData(form);
        //email(formData);
        // });
        //});

        event.preventDefault();
        window.grecaptcha.ready(function () {
            window.grecaptcha.execute('6LccvMQjAAAAAI7wLF3R_hrVj9ayNKwFGCPm06rw', { action: 'submit' }).then(async function (token) {
                // Send form value as well as token to the server

                try {

                    form.append('token', token);
                    event.preventDefault();
                    await fetch("https://cardcam.io/ccmail/contact.php", {
                        method: "post",
                        body: form
                    }).then(async (response) => {
                        if (response.status === 200) {
                            let data = await response.text();
                            try {
                                setSuccessMessage(JSON.parse(data).message)
                            }
                            catch (e) {
                                
                            }
                            setFormPosted(true);
                        } else {

                            setFormError(true);
                            setFormPosted(false);
                        }
                    })
                } catch (error) {
                    setFormError(true);
                    setFormPosted(false);
                }

            });
        });
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const inputStyle = {

    };

    const validateEmail = (e) => {
        const email = e.target.value;
        //console.log("validateEmail: " + email)
        setValidMail(String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ))
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setFormError(false);
    };

    if (!formPosted) {
        //console.log("formPosted: " + formPosted)
        return (
            <ThemeProvider theme={theme}>
                <Grid container
                    id={"contact"}
                    direction={direction}
                    justifyContent="center"
                    alignItems="center"
                    py={6}
                    sx={{ minHeight: "100vh", backgroundColor: "background.default", px: { md: "40px", xs: "30px" }, backdropFilter: "blur(20px)"  }}>
                    <Grid item xs={12} md={6} display="flex" px={2} sx={{ justifyContent: { md: direction === "row" ? "flex-end" : "flex-start", xs: "center" } }}>
                        <Box className="headline" sx={{ maxWidth: { md: "400px", xs: "100%" }, my: "12px"}} >
                            <PrismicRichText
                                field={sliceData.primary.contact_form_title}
                                components={{
                                    heading1: ({ children }) => <Typography variant={"h1"} sx={{ fontSize: "3em", lineHeight: "0.9em"}} color="text.primary">{children}</Typography>,
                                    strong: ({ children }) => <Typography sx={{ typography: { sm: 'h1', xs: 'h2', fontWeight: "800" } }} color="text.highlight" >{children}</Typography>

                                }}
                            />

                            <PrismicRichText
                                field={sliceData.primary.contact_form_text}
                                components={{
                                    paragraph: ({ children }) => <Typography pt={2} sx={{ typography: { sm: 'h3', xs: 'h4' } }} color="text.primary">{children}</Typography>,

                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} display="flex" px={2} sx={{ justifyContent: { md: direction === "row" ? "flex-start" : "flex-end", xs: "center" } }}>
                        <Box sx={{ maxWidth: { md: "400px", xs: "100%" } }} >
                            <form onSubmit={handleSubmit}>

                                <TextField
                                    id="name-input"
                                    name="firstname"
                                    fullWidth
                                    color="primary"
                                    label="Förnamn"
                                    size='small'
                                    type="text"
                                    value={formValues.firstname}
                                    onChange={handleInputChange}
                                    inputProps={{ style: inputStyle }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& > fieldset": { borderColor: theme.palette.background.paper },
                                        },
                                        my: "10px"
                                    }}
                                />
                                <TextField
                                    id="name-input"
                                    name="lastname"
                                    fullWidth
                                    color="primary"
                                    label="Efternamn"
                                    size='small'
                                    type="text"
                                    value={formValues.lastname}
                                    onChange={handleInputChange}
                                    inputProps={{ style: inputStyle }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& > fieldset": { borderColor: theme.palette.background.paper },
                                        },
                                        my: "10px"
                                    }}
                                />
                                <TextField
                                    id="email-input"
                                    name="email"
                                    onKeyUp={validateEmail}
                                    fullWidth
                                    label="E-postadress"
                                    size='small'
                                    type="email"
                                    value={formValues.email}
                                    onChange={handleInputChange}
                                    inputProps={{ style: inputStyle }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& > fieldset": { borderColor: theme.palette.background.paper },
                                        },
                                        my: "10px"
                                    }}
                                />
                                <TextField
                                    id="phone-input"
                                    name="phone"
                                    fullWidth
                                    label="Telefonnummer"
                                    size='small'
                                    type="phone"
                                    value={formValues.phone}
                                    onChange={handleInputChange}
                                    inputProps={{ style: inputStyle }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& > fieldset": { borderColor: theme.palette.background.paper },
                                        },
                                        my: "10px"
                                    }}
                                />
                                <TextField
                                    id="company-input"
                                    name="business"
                                    fullWidth
                                    label="Företag/organsation/förening"
                                    size='small'
                                    type="text"
                                    value={formValues.business}
                                    onChange={handleInputChange}
                                    inputProps={{ style: inputStyle }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& > fieldset": { borderColor: theme.palette.background.paper },
                                        },
                                        my: "10px"
                                    }}
                                />
                                <TextField
                                    id="message-input"
                                    name="message"
                                    fullWidth
                                    multiline
                                    size='small'
                                    label="Ev. meddelande"
                                    type="text"
                                    value={formValues.message}
                                    onChange={handleInputChange}
                                    inputProps={{ style: inputStyle }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& > fieldset": { borderColor: theme.palette.background.paper },
                                        },
                                        my: "10px"
                                    }}
                                />
                                <Button variant="contained" color="primary" size="large" fullWidth type="submit" sx={{
                                    my: "10px"
                                }}>
                                    Kontakta mig
                                </Button>
                                <Snackbar open={formError} autoHideDuration={6000} onClose={handleClose}>
                                    <Alert onClose={handleClose} variant="filled" severity="error" sx={{ width: '100%' }}>
                                        Någonting gick fel, försök igen!
                                    </Alert>
                                </Snackbar>
                                <Typography variant='tiny'>This site is protected by reCAPTCHA and the Google&nbsp;
                                    <Link href="https://policies.google.com/privacy" target="_blank">Privacy Policy</Link> and&nbsp;
                                    <Link href="https://policies.google.com/terms" target="_blank">Terms of Service</Link> apply.
                                </Typography>
                            </form>
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>
        )

    }
    else {
        return (
            <ThemeProvider theme={theme}>
                <Grid container
                
                    id={"contact"}
                    direction={direction}
                    justifyContent="center"
                    alignItems="center"
                    sx={{ minHeight: "100vh", backgroundColor: "background.default"}}>
                    <Grid item xs={12} display="flex" px={8} py={4} sx={{ justifyContent: "center" }}>
                        <Box >
                            <Typography sx={{ typography: { sm: 'h1', xs: 'h2' } }} color="text.primary">Tack för ditt meddelande!</Typography>
                            <Stack mt={3} direction="row" alignItems="center" spacing={2}><CheckCircleIcon color="success" size="90px" /><Typography sx={{ typography: { sm: 'h3', xs: 'h4' } }} color="text.primary">{successMessage}</Typography></Stack>
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>
        )
    }
}

export default ContactSlice
