import './App.css';

import { ThemeProvider } from '@emotion/react';
import { Link, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { usePrismicDocumentByUID } from '@prismicio/react';
import { useCallback, useEffect, useRef } from 'react';
import ContactSlice from './components/ContactSlice';
import Footer from './components/Footer';
import TextImageSlice from './components/TextImageSlice';
import TopNavigation from './components/TopNavigation';
import TopSlice from './components/TopSlice';
import { lighttheme } from "./themes";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import CustomerLogos from './components/CustomerLogos';
import SellingPointsSlice from './components/SellingPointsSlice';

import "@fontsource/kanit/600.css";


gsap.registerPlugin(ScrollTrigger);


function App() {
  const appRef = useRef(null)

  const elemRef = useCallback((node) => {
    if (node !== null) {
      const element = node;

      gsap.fromTo(
        element.querySelector("#topNavigation"),
        {
          y: "-100%",
        },
        {
          y: "0%",
          ease: "circ.out",
          scrollTrigger: {
            id: "topNavigation",
            start: "bottom bottom",
            end: "+=100",
            trigger: element.querySelector(".slice-top"),
            scrub: 1,
            markers: false,
          }
        },
      )

      



      gsap.fromTo("#topImage",
        {

        },
        {
          opacity: 0,
          ease: "none.none",
          y: "-200%",
          scale: 1.6,
          ease: "easeInOutSine",
          label: "topImage",
          scrollTrigger: {
            start: "1% top",
            end: "+=800",
            trigger: element.querySelector("#topSlice"),
            markers: false,
            scrub: true,
            pin: false,
            ease: "none.none",
            toggleActions: "play resume pause reverse"

          }
        })

      const gtl = gsap.timeline({
        scrollTrigger: {
          start: "6% top",
          end: "+=1500",
          trigger: element.querySelector("#topSlice"),
          markers: false,
          scrub: 1,
          pin: false,
          ease: "none.none",

        }
      })
      gtl.fromTo("#topContent",
        {
          background: "linear-gradient(0deg, #000 0%, #000 50%, #000  100%)",
        },
        {
          background: "linear-gradient(100deg, #000 0%, #190010 50%,#000 100%)",

          ease: "none.none",
          label: "topImage",

        })

      gtl.fromTo("#topContent",
        {
          background: "linear-gradient(100deg, #000 0%, #190010 50%, #000 100%)",
        },
        {
          background: "linear-gradient(150deg, #000 0%, #0e0019 50%,#000 100%)",

          ease: "none.none",
          label: "topImage",

        })

      gtl.fromTo("#topContent",
        {
          background: "linear-gradient(150deg, #000 0%, #0e0019 50%, #000 100%)",
        },
        {
          background: "linear-gradient(180deg, #000 0%, #001924 50%, #000 100%)",

          ease: "none.none",
          label: "topImage",

        })

        gtl.fromTo("#topContent",
        {
          background: "linear-gradient(180deg, #000 0%, #001924 50%, #000 100%)",
        },
        {
          background: "linear-gradient(220deg, #000 0%, #001314 50%, #000 100%)",

          ease: "none.none",
          label: "topImage",

        })


     

      gsap.fromTo("#topContent",
        {
          opacity: 0,
        },
        {
          ease: "none.none",
          opacity: 1,
          label: "topContent",
          scrollTrigger: {
            start: "4% top",
            end: "+=50",
            trigger: element.querySelector("#topSlice"),
            markers: false,
            scrub: true,
            pin: false,
            ease: "none.none",
            toggleActions: "play resume pause reverse"

          }
        })





      gsap.to(
        element.querySelector("#topSlice"),
        {
          ease: "easeInSine",

          scrollTrigger: {
            id: "#topSlice",
            start: "30% top",
            end: "+=200",
            trigger: element.querySelector("#transparentTop"),
            scrub: true
          },
        }
      );


      const headlines = gsap.utils.toArray(".headline");

      /*
            headlines.forEach((a, index) => {
              gsap.fromTo(
                a,
                {
                  opacity: 0,
                  y: 20,
                },
                {
                  y: 0,
                  duration: 1,
                  opacity: 1,
                  ease: "easeIn",
      
                  scrollTrigger: {
                    id: ".headline",
                    trigger: a,
                    start: "top 80%",
      
                    markers: false,
                  },
      
      
                }
              )
            })
            const descriptions = gsap.utils.toArray(".description");
      
      
            descriptions.forEach((a, index) => {
              gsap.fromTo(
                a,
                {
                  opacity: 0,
                },
                {
                  delay: .3,
                  duration: 1,
                  opacity: 1,
                  ease: "easeIn",
      
                  scrollTrigger: {
                    id: ".description",
                    trigger: a,
                    start: "top 80%",
      
                    markers: false,
                  },
      
      
                }
              )
            })
            */
    }
  }, [])


  if (process.env.NODE_ENV === 'production') {
    console.log = () => { }
    console.error = () => { }
    console.debug = () => { }
  }

  //const [firstDocument] = useFirstPrismicDocument()
  const [startPage] = usePrismicDocumentByUID('homepage', 'the-start-page')

  console.log("startPage")
  console.log(startPage)

  useEffect(() => {
    if (startPage) {
      console.log("startPage")
      console.log(startPage)

    }
  }, [startPage])

  const generateContent = () => {
    if (startPage) {
      let slices = []

      let currentDirection = "row"

      console.log("startPage.data.body", startPage.data)
      console.log(startPage.data.title)

      startPage.data.body.map((sliceData, index) => {
        // console.log("sliceData.slice_type", sliceData.slice_type)
        switch (sliceData.slice_type) {
          case "header__image_and_text":
            slices.push(<div class="slice slice-top"><TextImageSlice key={index.toString()} sliceData={sliceData} index={index} direction={currentDirection} /></div>)
            break;
          case "selling_points":
            slices.push(<div class="slice slice-top"><SellingPointsSlice key={index.toString()} sliceData={sliceData} index={index} direction={currentDirection} /></div>)
            break;
          case "contact_form":
            slices.push(<div class="slice slice-top"><ContactSlice key={index.toString()} sliceData={sliceData} index={index} direction={currentDirection} /></div>)
            break;
          case "customer_logos":
            slices.push(<div class="slice slice-top"><CustomerLogos key={index.toString()} sliceData={sliceData} index={index} direction={currentDirection} /></div>)
            break;
        }

        currentDirection = index % 2 !== 1 ? "row" : "row-reverse";

      })
      console.log("slices", slices)
      return slices
    }
    return null
  }



  function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://mui.com/">
          Your Website
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }



  if (startPage) {
    return (
      <ThemeProvider theme={lighttheme}>
        <div className="App" ref={elemRef} style={{ maxWidth: "100vw", background: "#000000"}}>
          <Container disableGutters maxWidth={"100%"} >
            <TopNavigation />
            <TopSlice sliceData={startPage?.data} color="accent.main" direction="row" />
            {generateContent()}
            <Footer />
          </Container>
        </div>
      </ThemeProvider>

    )
  } else {
    return (
      <div style={{ width: "100vw", height: "100vh", backgroundColor: "#000" }}>
      </div>
    )
  }

}

export default App;
