import Typography from '@mui/material/Typography';
import * as React from 'react';

import Button from '@mui/material/Button';

import { Box, Grid, Stack, ThemeProvider } from '@mui/material';
import { PrismicRichText } from '@prismicio/react';
import { bctheme, cctheme, darktheme, fctheme, lighttheme } from '../themes';

import { useRef } from 'react';

import ImageCarousel from './ImageCarousel';
import gsap from 'gsap';


function htmlSerializer(type, element, text, children) {
    if (type === 'label')
        return `<strong className="${element.data.label}">${children}</strong>`
    return null
}

const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const MODE_TEXT = "text";
const MODE_MEDIA = "media";
const MODE_TEXT_MEDIA = "text-media";
const MODE_HEADLINE = "headline";


const TextImageSlice = ({ sliceData, index, direction }) => {
    let mode = MODE_TEXT_MEDIA
 
    let theme = lighttheme;
    const imageRef = useRef(null);
    const imageSliceRef = useRef(null);


    const logoRef = useRef(null);

    console.log("%c---------------", "color: yellow; font-size: 40px;");

    /*
                        once: true,

      onLeave: self => {
                        let start = self.start;
                        self.scroll(self.start);
                        self.disable();

                        // add true
                        self.animation.progress(1, true);
                        ScrollTrigger.refresh();
                        window.scrollTo(0, start);
                    },
                    once: true,

      onLeave: self => {
                        let start = self.start;
                        self.scroll(self.start);
                        self.disable();

                        // add true
                        self.animation.progress(1, true);
                        ScrollTrigger.refresh();
                        window.scrollTo(0, start);
                    },

                    /*
                     snap: {
                        snapTo: "labels",
                        duration: { min: 1, max: 2 },
                        delay: 0.5,
                        ease: "none.none",
                    },
                    
    */

    const productImageRef = React.useCallback((node) => {

        if (node !== null) {
            const element = node;

            gsap.fromTo(
                element,
                {

                    opacity: 1,
                    y: "60%",
                },
                {
                    opacity: 1, 
                    y: "0%",

                    ease: "none.none",
                    scrollTrigger: {
                        id: "productImage",
                        start: "top 130%",
                        end: "top 60%",
                        trigger: element,
                        markers: false,
                        scrub: true,
    
                    },
                },
                "start"
            )
            
        }
    }, []);



    //theme.palette.background.bright
    const textImageRef = React.useCallback((node) => {

        if (node !== null) {
            const element = node;


            let tl = gsap.timeline({

                scrollTrigger: {
                    id: "sliceimage",
                    start: "top 40%",
                    end: "+=100",
                    trigger: element,
                    markers: false,
                    scrub: true,

                },

            })
            tl.addLabel("start")





            tl.fromTo(
                element.querySelector("#bodyTextContainer"),
                {
                    opacity: "1",
                },
                {
               
                    opacity: "1",
                    ease: "none.none",
                    delay: 0.3,
                    duration: 0.3,
                },
                "start"
            )




            tl.addLabel("mid")

            /*

            tl.fromTo(
                element,
                {

                },

                {
                    ease: "none.none",
                    borderLeft: "10px solid " + theme.palette.background.default,
                    duration: 0.1,


                },
                "end"
            )
           
            tl.to(
                element.querySelector("#gridWrapper"),
                {
                },
                "end"
            )

            tl.fromTo(
                element.querySelector("#bodyTextContainer"),
                {
                    opacity: "0",
                },
                {
                    opacity: "1",
                    ease: "none.none",
                },
                "end"
            )




            tl.addLabel("end")
                */
        }


    }, []);


    console.log("%c----sliceData---", "color: blue; font-size: 20px;");
    console.log(sliceData);
    console.log(sliceData.primary.slice_title[0]?.text);

    console.log("%c--------", "color: blue; font-size: 20px;");
    const headline = sliceData.primary.slice_title[0]?.text;

    console.log("%c---headline", "color: red; font-size: 20px;", headline);

    //console.log("button ------------------------------------>");
    //console.log(sliceData.primary.button_link)

    const buttonLink = sliceData.primary.button_link.target === "_blank" ? { url: sliceData.primary.button_link.url, type: "external" } : sliceData.primary.button_link.url ? { url: "#" + sliceData.primary.button_link.url.split("#")[1], type: "internal" } : null;

    //console.log("===============================)> buttonLink ")
    //console.log(buttonLink)

    //console.log("sliceData");
    // console.log(sliceData);

    switch (sliceData.primary.color_theme) {
        case "Bright":
            theme = lighttheme;
            break
        case "Dark":
            theme = darktheme;
            break
        case "Colorful Yellow":
            theme = bctheme;
            break
        case "Colorful Surprise":
            theme = fctheme;
            break
        case "Semi-bright":
            theme = cctheme;
            break
        default:
            theme = lighttheme;
    }

    console.log("%c---checking: ", "color: red; font-size: 40px;", sliceData);
    console.log(sliceData.primary.sub_title[0]?.text);
    console.log(sliceData.primary.slice_title[0]?.text);

    if (sliceData.items[0].slider_image.url === undefined && (sliceData.primary.slice_title[0].text !== "" || sliceData.primary.sub_title[0].text !== "")) {
        //console.log("no media")
        mode = MODE_TEXT;

        if (sliceData.primary.sub_title[0]?.text === "") {
            mode = MODE_HEADLINE;
        }
        //console.log("mode is inside " + mode);
    }
    else if (sliceData.primary.slice_title[0]?.text === "" && sliceData.primary.sub_title[0].text === "") {
        // console.log("mode is inside " + mode);
        mode = MODE_MEDIA;
    }
    else if (sliceData.primary.sub_title[0].text !== "" && sliceData.items.length > 0) {
        //console.log("mode is inside " + mode);
        mode = MODE_TEXT_MEDIA;
    }
    else {
        mode = MODE_TEXT_MEDIA;

    }


    console.log("%c---mode: ", "color: red; font-size: 40px;", mode);


    const Media = () => {
        if (sliceData.items[0].slider_image.url !== undefined) {
            if (sliceData.items.length === 1) {
                return (
                    <div ref={imageSliceRef} style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>

                        <Box
                            ref={productImageRef}
                            component="img"
                            id='Media'
                            style={{
                                maxHeight: "100%", width: "100%", objectFit: "cover", maxWidth: "100vw"
                            }}
                            alt={sliceData.items[0].slider_image.alt}
                            src={sliceData.items[0].slider_image.url}
                        />
                    </div>


                )
            }
            if (sliceData.items.length === 2) {
                return (

                    <Box
                        ref={imageRef}

                        sx={{
                            maskImage: "linear-gradient(to bottom, #000000 1%, #000000 5%, #000000 70%, #000000 99%);",
                            height: { xs: "100%", md: "100%" },
                            overflow: "hidden",
                            width: "100%",
                            backgroundImage: `url(${sliceData.items[0].slider_image.url})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                        }}

                    />
                )
            }
            else {
                const images = sliceData.items.map((item, index) => {
                    return (
                        item.slider_image.url
                    )
                })

                console.log("images");

                return (
                    <Box
                        ref={imageRef}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <ImageCarousel
                            imageArray={images}
                            subtitle="Kundcase - Växjö Lakers"
                        />
                    </Box>

                )
            }
        }
        else {

            return null
        }
    }

    const MediaSmall = () => {
        if (sliceData.items[0].slider_image.url !== undefined) {
            if (sliceData.items.length === 1) {
                return (
                    <div ref={imageSliceRef} style={{ width: "100%", height: "100%", maxWidth: "100vw", display: "flex", justifyContent: "center", alignItems: "center" }}>

                        <Box
                            component="img"
                            ref={productImageRef}
                            id='MediaSmall'
                            style={{
                                width: "80vw", maxWidth: "100vw"
                            }}
                            alt={sliceData.items[0].slider_image.alt}
                            src={sliceData.items[0].slider_image.url}
                        />
                    </div>


                )
            }
            if (sliceData.items.length === 2) {
                return (

                    <Box
                        ref={imageRef}

                        sx={{
                            maskImage: "linear-gradient(to bottom, #000000 1%, #000000 5%, #000000 70%, #000000 99%);",
                            height: { xs: "100%", md: "100%" },
                            overflow: "hidden",
                            width: "100%",
                            backgroundImage: `url(${sliceData.items[0].slider_image.url})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                        }}

                    />
                )
            }
            else {
                const images = sliceData.items.map((item, index) => {
                    return (
                        item.slider_image.url
                    )
                })

                console.log("images");

                return (
                    <Box
                        ref={imageRef}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <ImageCarousel
                            imageArray={images}
                            subtitle="Kundcase - Växjö Lakers"
                        />
                    </Box>

                )
            }
        }
        else {

            return null
        }
    }

    const MediaSide = () => {
        if (sliceData.items[0].slider_image.url !== undefined) {
            if (sliceData.items.length === 1) {
                return (
                    <div ref={imageSliceRef} style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>

                        <Box
                            component="img"
                            id='MediaSide'
                            style={{
                                maxHeight: "100%", width: "100%", objectFit: "cover", paddingTop: "0px", maxWidth: "100vw"
                            }}
                            alt={sliceData.items[0].slider_image.alt}
                            src={sliceData.items[0].slider_image.url}
                        />
                    </div>


                )
            }
            if (sliceData.items.length === 2) {
                return (

                    <Box
                        ref={imageRef}

                        sx={{
                            maskImage: "linear-gradient(to bottom, #000000 1%, #000000 5%, #000000 70%, #000000 99%);",
                            height: { xs: "100%", md: "100%" },
                            overflow: "hidden",
                            width: "100%",
                            backgroundImage: `url(${sliceData.items[0].slider_image.url})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                        }}

                    />
                )
            }
            else {
                const images = sliceData.items.map((item, index) => {
                    return (
                        item.slider_image.url
                    )
                })

                console.log("images");

                return (
                    <Box
                        ref={imageRef}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <ImageCarousel
                            imageArray={images}
                            subtitle="Kundcase - Växjö Lakers"
                        />
                    </Box>

                )
            }
        }
        else {

            return null
        }
    }



    if (sliceData.primary.slice_visible) {
        /*console.log("mode is " + mode);
        console.log("====>sliceData.primary ", sliceData.primary);
        console.log("====>sliceData.items ", sliceData.primary.margins);
    */

        if (sliceData.primary.type === "headline") {

            // const link = sliceData.primary.slice_title[0]?.text === 

            return (
                <ThemeProvider theme={theme}>
                    <Stack direction="column" alignItems="center" justifyContent="center" sx={{ width: "100%", minHeight: "22vh", backgroundColor: "#000", p: "0", overflow: "hidden", m: "0" }}>
                        <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Typography variant="h1" sx={{ color: "text.primary", textAlign: "center", fontWeight: "bold", fontSize: { xs: "2.5rem", md: "3.5rem" } }}>
                                {sliceData.primary.slice_title[0]?.text}
                            </Typography>
                        </Box>
                    </Stack>
                </ThemeProvider>
            )
        }
        else if (sliceData.primary.type === "text_image") {
            //console.log("text_image")
            //console.log(sliceData)
            return (
                <ThemeProvider theme={theme}>
                    <Stack ref={textImageRef} id="textImageContainer" direction={{ xs: "column", md: "column" }} alignItems="center" justifyContent="center"
                        sx={{
                            backgroundColor: "#000", overflow: "hidden", m: "0"
                        }}>
                        <Stack direction={{ xs: "column", md: "column" }} alignItems="center" justifyContent="center" sx={{ maxWidth: "900px", width: "100%", px: { xs: 2, md: 0 }, overflow: "hidden", m: "0", backgroundColor: "#eeeeee", borderRadius: "30px", mt: 3, }}>
                            <Box mt={4} display="flex" sx={{ width: "100%", mb: 3 }}>
                                <img id={"productlogo"} ref={logoRef} src={sliceData.primary.optional_small_ident_image.url} alt="image" style={{ width: "100%" }} />
                            </Box>
                            <Stack id="contentContainer" direction={{ xs: "column", md: "row" }} alignItems="center" justifyContent="center" sx={{ overflow: "hidden", width: "100%" }}>


                                <Stack index={index}
                                    direction={"column"}
                                    id="gridWrapper"
                                    justifyContent={{ xs: "flex-start", md: "center" }}
                                    sx={{ overflow: "hidden", alignItems: "center", justifyContent: "flex-start", flexWrap: "nowrap", width: "100%" }}>

                                    <Box id="bodyTextContainer" display="flex" flexDirection={"column"} alignContent={"flex-start"} justifyContent={"flex-start"} mt={3} px={{ xs: 2, md: 8, width: "100%" }}  >
                                        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", pb: 5 }} >
                                            <PrismicRichText
                                                field={sliceData.primary.slice_title}
                                                components={{
                                                    heading1: ({ children }) => <Typography my={0} variant={"h3"} sx={{ fontSize: { xs: '2rem', md: '3.3rem' } }} style={{}}>{children} </Typography>,
                                                    strong: ({ children }) => <Typography my={0} variant={"h3"} sx={{}} >{children}</Typography>
                                                }}
                                            />
                                        </Box>
                                        <PrismicRichText
                                            field={sliceData.primary.sub_title}
                                            components={{
                                                heading2: ({ children }) => <Typography my={0} variant={"p"} sx={{ pb: 2 }} color="text.primary" style={{}}>{children} </Typography>,
                                                strong: ({ children }) => <Typography my={0} variant={"p"} sx={{ fontWeight: 900 }} color="text.primary" >{children}</Typography>
                                            }}
                                        />
                                        <Box id="textImageTextContainer" sx={{ display: { xs: "flex", md: "none" }, width: "100vw", justifyContent: "center", alignItems: "center", overflow: "hidden", width: "100%" }} >
                                            <MediaSmall />
                                        </Box>

                                    </Box>

                                    <Box id="btnContainer" display="flex" alignItems={"flex-end"}>
                                        {buttonLink != null && <Button size="small" target={buttonLink.type === "external" ? "_blank" : "_self"} variant="contained" color="primary" fullWidth style={{ height: "60px", maxWidth: "300px", borderRadius: "30px", fontWeight: "700" }} sx={{ px: 5, my: 5 }} href={buttonLink?.url}>{sliceData.primary.button_label}</Button>}
                                    </Box>

                                </Stack>

                                <Box id="textImageTextContainer" sx={{ display: { xs: "none", md: "flex" }, justifyContent: "center", alignItems: "center", overflow: "hidden", minWidth: "400px" }} >
                                    <Media />
                                </Box>


                            </Stack>

                        </Stack>
                    </Stack>
                </ThemeProvider>
            )
        }
        else if (sliceData.primary.type === "text_image_small") {
            console.log("text_image")
            console.log(sliceData)
            return (
                <ThemeProvider theme={theme}>
                    <Stack id="textImageContainer" direction={{ xs: "column", md: "row" }} alignItems="center" justifyContent="center"
                        sx={{
                            width: "100%", height: { xs: "100%", md: "100%" }, backgroundColor: "background.default", p: "0", overflow: "hidden", m: "0"
                        }}>

                        <Grid container index={index}
                            direction={"column"}
                            id="gridWrapper"
                            justifyContent={"flex-start"}
                            sx={{ p: "0", height: "100%", minHeight: "100%", overflow: "hidden", m: "0", alignItems: "center", flexWrap: "nowrap", pt: { xs: 2, md: 0 } }}>

                            <Grid item id="bodyTextContainer" sx={{ overflow: "hidden" }} xs={8} md={8} display="flex" flexDirection={"column"} alignContent={"flex-start"} justifyContent={"flex-start"} mt={3} px={{ xs: 2, md: 8 }}>
                                <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", pb: 5 }} >
                                    <PrismicRichText
                                        field={sliceData.primary.slice_title}
                                        components={{
                                            heading1: ({ children }) => <Typography my={0} variant={"h3"} sx={{ fontSize: { xs: '2rem', md: '3.3rem' } }} style={{}}>{children} </Typography>,
                                            strong: ({ children }) => <Typography my={0} variant={"h3"} sx={{}} >{children}</Typography>
                                        }}
                                    />
                                </Box>
                                <PrismicRichText
                                    field={sliceData.primary.sub_title}
                                    components={{
                                        heading2: ({ children }) => <Typography my={0} variant={"p"} sx={{}} color="text.primary" style={{}}>{children} </Typography>,
                                        strong: ({ children }) => <Typography my={0} variant={"p"} sx={{ fontWeight: 900 }} color="text.primary" >{children}</Typography>
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2} md={2} display="flex" alignItems={"flex-end"} mb={8}>
                                {buttonLink != null && <Button target={buttonLink.type === "external" ? "_blank" : "_self"} variant="contained" color="primary" fullWidth style={{ height: "60px", maxWidth: "300px", borderRadius: "30px", fontWeight: "700" }} sx={{ px: 5 }} href={buttonLink?.url}>{sliceData.primary.button_label}</Button>}
                            </Grid>
                        </Grid>

                        <Box id="textImageTextContainer" sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#939393", overflow: "hidden", }}>
                            <MediaSide />
                        </Box>

                    </Stack>
                </ThemeProvider>
            )
        }
        else if (sliceData.primary.type === "text") {
            console.log("text")
            console.log(sliceData)
            return (
                <ThemeProvider theme={theme}>
                    <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "flex-start", backgroundColor: "#000", overflow: "hidden", }}>
                        <Stack id="text_container" direction={{ xs: "column", md: "row" }} alignItems="center" justifyContent="flex-start"
                            sx={{
                                maxWidth: "900px", minHeight: { xs: "100%", md: "100vh" }, backgroundColor: "background.default", p: "0", overflow: "hidden", m: "0", borderRadius: "30px"
                            }}>

                            <Grid container index={index}
                                direction={"column"}
                                id="gridWrapper"
                                justifyContent={"flex-start"}
                                sx={{ p: "0", height: "100%", minHeight: "100vh", overflow: "hidden", m: "0", alignItems: "center", flexWrap: "nowrap", pt: "0" }}>
                                <Grid item xs={2} md={2} display="flex" alignItems={"flex-end"} mb={0}  >
                                    <div style={{overflow: "hidden", width: "100%", height: "100%"}}>
                                    <img id={"productlogo"} ref={logoRef} src={sliceData.primary.optional_small_ident_image.url} alt="image" style={{ width: "100%" }} />
                                    </div>
                                </Grid>
                                <Grid item id="text_bodyTextContainer" sx={{ overflow: "hidden" }} xs={8} md={8} display="flex" flexDirection={"column"} alignContent={"flex-start"} justifyContent={"flex-start"} mt={3} px={{ xs: 2, md: 8 }}>

                                    <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", pb: 6, mt: { xs: 0, md: 4} }} >
                                        <PrismicRichText
                                            field={sliceData.primary.slice_title}
                                            components={{
                                                heading1: ({ children }) => <Typography my={0} variant={"h3"} sx={{ fontSize: { xs: '2rem', md: '3.3rem' } }} style={{}}>{children} </Typography>,
                                                strong: ({ children }) => <Typography my={0} variant={"h3"} sx={{}} >{children}</Typography>
                                            }}
                                        />
                                    </Box>
                                    <PrismicRichText
                                        field={sliceData.primary.sub_title_2}
                                        components={{
                                            heading2: ({ children }) => <Typography my={0} variant={"p"} sx={{}} color="text.primary" style={{}}>{children} </Typography>,
                                            strong: ({ children }) => <Typography my={0} variant={"p"} sx={{ fontWeight: 900 }} color="text.primary" >{children}</Typography>
                                        }}
                                    />
                                    <PrismicRichText
                                        field={sliceData.primary.sub_title}
                                        components={{
                                            heading2: ({ children }) => <Typography my={0} variant={"h4"} sx={{ mt: 8 }} color="text.primary" style={{}}>{children} </Typography>,
                                            strong: ({ children }) => <Typography my={0} variant={"h4"} sx={{ fontWeight: 900, mt: 8 }} color="text.primary" >{children}</Typography>
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={2} md={2} display="flex" alignItems={"flex-end"} my={8}>
                                    {buttonLink != null && <Button target={buttonLink.type === "external" ? "_blank" : "_self"} variant="contained" color="primary" fullWidth style={{ height: "60px", maxWidth: "300px", borderRadius: "30px", fontWeight: "700" }} sx={{ px: 5 }} href={buttonLink?.url}>{sliceData.primary.button_label}</Button>}
                                </Grid>
                            </Grid>



                        </Stack>
                    </Box>
                </ThemeProvider>
            )
        }


    } else {
        return null;
    }
};
export default TextImageSlice
