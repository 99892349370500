import * as prismic from '@prismicio/client'

// Fill in your repository name
export const repositoryName = 'vxodesign'

export const client = prismic.createClient(repositoryName, {
  // If your repository is private, add an access token
  accessToken: 'MC5ZNzU5VWhFQUFDSUFNMk1G.77-977-9Ze-_ve-_vSXvv71r77-9NAVc77-977-977-977-9Ne-_ve-_ve-_vXoF77-977-9du-_vTvvv73vv71e77-977-9',

  // This defines how you will structure URL paths in your project.
  // Update the types to match the Custom Types in your project, and edit
  // the paths to match the routing in your project.
  //
  // If you are not using a router in your project, you can change this
  // to an empty array or remove the option entirely.
  /*
  routes: [
    {
      type: 'testpage',
      path: '/',
    },
  ],
  */
})