import Typography from '@mui/material/Typography';
import * as React from 'react';


import { Box, Grid, Stack, ThemeProvider } from '@mui/material';
import { PrismicRichText } from '@prismicio/react';
import { lighttheme } from '../themes';

import { useRef } from 'react';

import { gsap } from "gsap";
import { useEffect } from 'react';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { color } from '@mui/system';
gsap.registerPlugin(ScrollTrigger)


function htmlSerializer(type, element, text, children) {
    if (type === 'label')
        return `<strong className="${element.data.label}">${children}</strong>`
    return null
}

const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const MODE_TEXT = "text";
const MODE_MEDIA = "media";
const MODE_TEXT_MEDIA = "text-media";

const colors = [
    { background: "#FF76C0", color: "#FFE1F1" },
    { background: "#A771FF", color: "#EDE2FF" },

    { background: "#EDFF7D", color: "#F8FFD0" },
    { background: "#FDCB6B", color: "#FFF4E0" },

    { background: "#83FFD2", color: "#E3FFF5" },
    { background: "#71CCFF", color: "#E2F5FF" },


    { background: "#A1FE68", color: "#ECFFE0" },
    { background: "#68FEA4", color: "#DFFFEC" },


]


const SellingPointsSlice = ({ sliceData, index, direction }) => {
    let mode = MODE_TEXT_MEDIA

    let theme = lighttheme
    const imageRef = useRef(null);
    const imageSliceRef = useRef(null);
    const containerRef = useRef(null);
    const rootRef = useRef(null);



    const [headline, setHeadline] = sliceData.primary.headline[0].text;

    //console.log("button ------------------------------------>");
    //console.log(sliceData.primary.button_link)

    const buttonLink = null

    const spContainerRef = React.useCallback((node) => {
        if (node !== null) {
            const gtl = gsap.timeline({
                scrollTrigger: {
                  start: "top 80%",
                  end: "bottom 30%",
                  trigger: node,
                  markers: false,
                  scrub: true,
                  pin: false,
                  ease: "none.none",
        
                }
              })
              gtl.fromTo(node,
                {
                  background: "linear-gradient(-50deg, #000 0%, #000 50%, #000  100%)",
                },
                {
                  background: "linear-gradient(0deg, #000 0%, #29001a 50%,#000 100%)",
        
                  ease: "none.none",
                  label: "topImage",
        
                })
        
              gtl.fromTo(node,
                {
                  background: "linear-gradient(0deg, #000 0%, #29001a 50%, #000 100%)",
                },
                {
                  background: "linear-gradient(50deg, #000 0%, #170029 50%,#000 100%)",
        
                  ease: "none.none",
                  label: "topImage",
        
                })
        
              gtl.fromTo(node,
                {
                  background: "linear-gradient(50deg, #000 0%, #170029 50%, #000 100%)",
                },
                {
                  background: "linear-gradient(150deg, #000 0%, #00202e 50%, #000 100%)",
        
                  ease: "none.none",
                  label: "topImage",
        
                })
        
                gtl.fromTo(node,
                {
                  background: "linear-gradient(150deg, #000 0%, #00202e 50%, #000 100%)",
                },
                {
                  background: "linear-gradient(220deg, #000 0%, #002224 50%, #000 100%)",
        
                  ease: "none.none",
                  label: "topImage",
        
                })
            }
    }, [])


    const sellingpointsRef = React.useCallback((node) => {

        if (node !== null) {
            //console.log("node");
            //console.log(node);
            //console.log("node.current");
            //console.log(node.current);
            //console.log("node.current.children");
            //console.log(node.current.children);

            //console.log("children");
            //console.log(children);

            /*

            ScrollTrigger.batch(spbox, {
                onEnter: elements => {
                  gsap.fromTo(elements, {
                    alpha: 0,
                    y: 60,
                    stagger: 0.8
                  },
                  {
                    alpha: 1,
                    y: 0,
                    stagger: 0.8,
                  }
                  )
                },
                once: true
              });
              */

              /*

            const spbox = gsap.utils.toArray(".spbox");


            spbox.forEach((a, index) => {
                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: a,
                        start: "top 90%",
                        end: "bottom top",
                        markers: false,
                        scrub: true,
                        onEnter: () => console.log("enter"),

                    }
                })
                tl.fromTo(a,
                    {
                        opacity: "0",
                    },
                    {

                        opacity: "1",

                        ease: "Sin.easeIn"

                    })
                    tl.to(a,
                  
                        {
    
                            opacity: "1",
    
                            ease: "Sin.easeOut",
    
                        })
                    tl.to(a,
                  
                    {

                        opacity: "0",

                        ease: "Sin.easeOut",

                    })
            })



            
*/


            //tl.fromTo(children, { opacity: 0, y: 50 }, { opacity: 1, y: 0, stagger: 0.2, duration: 1.5, ease: "power3.inOut" })

            //tl.fromTo(children, { opacity: 0, y: 50 }, { opacity: 1, y: 0, stagger: 0.2, duration: 1.5, ease: "power3.inOut" })

            //tl.fromTo(children, { opacity: 0, y: 50 }, { opacity: 1, y: 0, stagger: 0.2, duration: 1.5, ease: "power3.inOut" })

            //tl.fromTo(children, { opacity: 0, y: 50 }, { opacity: 1, y: 0, stagger: 0.2, duration: 1.5, ease: "power3.inOut" })



        }
    }, []);

    useEffect(() => {
        console.log("useEffect");


    }, [sliceData])

    setTimeout(() => {
        console.log("setTimeout");
        console.log(sliceData);

    }, 2000);

    //sliceData.primary.button_link.target === "_blank" ? { url: sliceData.primary.button_link.url, type: "external" } : sliceData.primary.button_link.url ? { url: "#" + sliceData.primary.button_link.url.split("#")[1], type: "internal" } : null;

    //console.log("===============================)> buttonLink ")
    //console.log(buttonLink)

    //console.log("sliceData");
    // console.log(sliceData);
    /*

    switch (sliceData.primary.color_theme) {
        case "Bright":
            theme = lighttheme;
            break
        case "Dark":
            theme = darktheme;
            break
        case "Colorful Yellow":
            theme = accenttheme;
            break
        default:
            theme = lighttheme;
    }
*/
    /*
    if (sliceData.items[0].slider_image.url === undefined && (sliceData.primary.slice_title[0].text !== "" || sliceData.primary.sub_title[0].text !== "")) {
        //console.log("no media")
        mode = MODE_TEXT;
        //console.log("mode is inside " + mode);
    }
    else if (sliceData.primary.slice_title[0].text === "" && sliceData.primary.sub_title[0].text === "") {
        // console.log("no text")
        mode = MODE_TEXT;
        // console.log("mode is inside " + mode);
        mode = MODE_MEDIA;
    }
    else {
        // console.log("mode is inside " + mode);
    }

*/

    const Media = ({ url }) => {

        //console.log("Media")
        //console.log(url)


        if (url === undefined) return null;
        //console.log("sliceData.items[0].slider_image.url " + url)
        return (
            <Box sx={{ width: "100%", textAlign: "center" }}>
                <img src={url} alt="image" style={{ width: "100%", height: "auto" }} />
            </Box>
        )

    }






    if (sliceData.primary.slice_visible) {


        //console.log("mode is text!!!!!!!!!!!!!! " + mode);

        let itemWidth = 6;

        if (sliceData.primary.margins === "Extra Large") {
            itemWidth = 4;
        }



        return (
            <ThemeProvider theme={theme}>
                <Box  id="spContainer" ref={spContainerRef} sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", overflow: "hidden" }} pb={4}>
                    <Stack id="" direction={{ xs: "column", md: "row" }} alignItems="center" justifyContent="center"
                        sx={{
                            maxWidth: "900px", height: { xs: "100%", md: "100%" }, p: "0", overflow: "hidden", m: "0"
                        }}>


                        <Grid container index={index}
                            ref={sellingpointsRef}
                            direction={"row"}
                            id="gridWrapper"
                            spacing={3}
                            mt={0}
                            mb={2}
                            justifyContent={"center"}
                            sx={{ overflow: "hidden" }}
                        >
                            {sliceData.items.map((item, index) => {
                                { console.log("item", item) }
                                return (

                                    <Grid item className="sellingPoint" sx={{ overflow: "hidden" }} xs={12} md={6} display="flex" flexDirection={"column"} alignContent={"flex-start"} justifyContent={"flex-start"} >

                                        {false &&
                                            <Box sx={{ maxHeight: "192px", display: "flex", justifyContent: "center", borderRadius: "300px", alignItems: "center", backgroundColor: "#fcfcfc", overflow: "hidden", }}>
                                                <Media url={item.icon.url} />
                                            </Box>
                                        }
                                        <Box className="spbox" sx={{ display: "flex", flexDirection: "column", p: "2em", height: "100%" }} alignContent={"flex-start"} justifyContent={"flex-start"}>
                                            <Box id="sptextContainer" style={{ background: "transparent" }}>

                                                <Box sx={{ display: "flex" }} alignContent={"flex-start"} justifyContent={"flex-start"}>
                                                    <PrismicRichText
                                                        field={item.sellingpoint}
                                                        components={{
                                                            heading1: ({ children }) => <Typography color={"#fff"} sx={{ typography: "sp_headline" }}>{children}</Typography>,
                                                            strong: ({ children }) => <Typography variant="hsubtitle11" color={colors[index].background} >{children}</Typography>,
                                                        }}
                                                    />
                                                </Box>
                                                <Box sx={{ height: "100%" }}>
                                                    <PrismicRichText
                                                        field={item.description}
                                                        components={{
                                                            paragraph: ({ children }) => <Typography color={"#fff"} sx={{ typography: { sm: 'sp_description', xs: 'sp_description' } }}>{children}</Typography>,
                                                            strong: ({ children }) => <Typography variant="hsubtitle11" color={colors[index].color} sx={{ typography: { sm: 'sp_description', xs: 'sp_description' } }}>{children}</Typography>,

                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Stack>
                </Box>

            </ThemeProvider>
        )
    } else {
        return null;
    }
}

export default SellingPointsSlice


/*
 <Stack
                    direction={"row"}
                    alignItems={"flex-start"}
                    ref={rootRef}   
                    sx={{  maskImage: "linear-gradient(to bottom, #000000 1%, #000000 10%, #000000 90%, #000000 99%);", overflow: "hidden", justifyContent: "flex-start", backgroundColor: "background.default", pt: { md: "30px", xs: "40px" }, pb: { md: "55px", xs: "60px" }, px: { md: "80px", xs: "30px" }}}
                    
                    >
                    
                    
                    */